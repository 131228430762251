<template>
    <div :class="[isLoadingFirstTime ? 'pointer-event-none' : '', 'container-fluid bg-white']"
        style="height:91vh; overflow: hidden;">
        <div class=" row questions-not-font flex-column h-100 d-flex justify-content-center align-items-center"
            v-if="QuestionsList < 1 && isLoadingFirstTime == false">
            <img src="https://www.quizell.com/_nuxt/img/why_quizell_male.04fc678.svg" alt="Svg" style="width: 20rem;">
            <div class="row text-center px-2">
                <div class="col-12">
                    <h3>Questions Not Available</h3>
                </div>
                <div class="col-12">
                    <h5>Please <router-link :to="`/customize/create/${id}`">Create Questions</router-link> then match the
                        products</h5>
                </div>
            </div>

        </div>
        <div class="row" v-else>
            <!-- Questions Started -->
            <div class="col-4 pt-3">
                <div class="row">
                    <h5 class="font-weight-bold p-3" style="color: #18191c">Question:</h5>
                </div>
                <div class="" style="height: 70vh; overflow: scroll;">
                    <div v-if="isLoadingFirstTime">
                        <div class="d-flex justify-content-between px-3 options py-3" v-for="index in 5" :key="index">
                            <b-skeleton class="w-100" type="input"></b-skeleton>
                        </div>
                    </div>
                    <div v-else
                        v-for="question, index in QuestionsList"
                        @click="selectQuestion(question, index)"
                        :key="question.id"
                        :class="[index == selected.questionIndex ? 'active-question' : '', 'question-row w-100 p-3 d-flex align-items-center']"
                        style="cursor: pointer;">
                        <div class="Order px-2 mt-n1 mr-3" style="flex: 0.08; text-align: center">{{ index + 1 }}</div>
                        <div class="question">{{ question.name }}</div>
                    </div>

                </div>
            </div>
            <!-- Question End -->
            <!-- Options Started -->
            <div class="col-3" style="background: #b3afb61a">
                <div class="row justify-content-between pt-3">
                    <h5 class="font-weight-bold p-3" style="color: #18191c">
                        Options:
                    </h5>
                    <div class=" p-3 btn" :disabled="isLoading" @click="getProductData()"><i
                            :class="[isLoading ? 'refresh' : '', 'fa fa-refresh']" v-b-tooltip.hover.bottom title="Refresh"
                            aria-hidden="true"></i></div>
                    <div class="w-100 p-0" style="height: 70vh; overflow: scroll;">
                        <div class="Options p-0">
                            <div class="w-100 singleOption mt-2 mb-3">
                                <div v-if="isLoadingFirstTime">
                                    <div class="d-flex justify-content-between align-items-center px-3 options py-3"
                                        v-for="index in 5" :key="index">
                                        <b-skeleton class="w-100" type="input"></b-skeleton>
                                    </div>
                                </div>
                                <div v-else
                                    :class="[selected.optionIndex == index ? 'active-option' : '', 'd-flex justify-content-between align-items-center px-3 options py-3']"
                                    v-for="options, index in selected.questionOptions" :key="options.id"
                                    @click="selectOption(index)">
                                    <div class="option-title">{{ options.option_value }}</div>
                                    <div class="order-info d-flex align-items-center">
                                        <div class="selected-item mr-2"><span>Selected {{ options.map_category }}</span>
                                        </div>
                                        <div class="Option-order">{{ mapCategoryCount(index) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Options Ended -->
            <!-- Products components Started -->
            <section class="col-5 pt-3">
                <div class="row px-3 py-1">
                    <div :class="[isShopifyUser?'btn-row justify-content-between':'justify-content-end',' d-flex align-items-center  w-100  mb-2']">
                        <div v-if="isShopifyUser">
                            <div :class="[button == activeCategory ? ' active-btn' : '', 'btn mr-2 category-btn text-capitalize']"
                                @click="OpenConfirmModal(button)" variant="light" v-for="button in actionBtns" :key="button"
                                style="color: #b3afb6; font-size: 12px;">{{ button == 'products' ? 'specific products' : button }}
                            </div>
                          
                        </div>
                        <b-button size="sm" :disabled="saveLoading" v-if="!isLoadingFirstTime" @click="saveData()"
                            style="background: #4d1b7e;">
                            <b-spinner small label="Small Spinner" v-if=saveLoading></b-spinner>
                            <span v-else>Save</span>
                        </b-button>
                    </div>
                   
                        <Products v-if="activeCategory == 'products'" ref="products" :isLoadingProducts="isLoadingFirstTime"
                        :products="products" :TagsList="TagsList.all" :CollectionList="CollectionList.all"
                        :VendorList="VendorList" :selected="selected" :question="QuestionsList[selected.questionIndex]"
                        @importItems="importItems"></Products>
                    <div class="w-100" v-if="isShopifyUser">
                    <Collections v-if="activeCategory == 'collections'" :products="products.all"
                        :isLoadingFirstTime="isLoadingFirstTime" :collections="CollectionList" :selected="selected"
                        :question="QuestionsList[selected.questionIndex]" @importItems="importItems"></Collections>
                    <Tags v-if="activeCategory == 'tags'" :products="products.all" :isLoadingFirstTime="isLoadingFirstTime"
                        :tags="TagsList" :selected="selected" :question="QuestionsList[selected.questionIndex]"
                        @importItems="importItems"></Tags>
                    <Vendors v-if="activeCategory == 'vendors'" :products="products.all"
                        :isLoadingFirstTime="isLoadingFirstTime" :vendors="VendorList" :selected="selected"
                        :question="QuestionsList[selected.questionIndex]" @importItems="importItems"></Vendors>
                    
                </div>
                <b-modal id="confirmModal" title="Change Match">
                                <div class="d-flex justify-content-center align-items-center">
                                    <i class="fa fa-info-circle mr-4" aria-hidden="true" style="color:#4d1b7e"></i>
                                    If you want to change from {{ activeCategory }} to other, the previous match will be
                                    removed.
                                </div>
                                <template #modal-footer>

                                    <div class="w-100 d-flex justify-content-between">
                                        <div class="div">
                                            <b-form-checkbox id="remind-me-again" name="remind-me-again"
                                                v-model="dontRemindAgain">
                                                <p class="p-0">Don't remind me again</p>
                                            </b-form-checkbox>
                                        </div>
                                        <div class="modal-b">
                                            <b-button size="sm" @click="ModalActions(false)"
                                                class="mr-2"><span>Cancel</span></b-button>
                                            <b-button size="sm" @click="ModalActions(true)"
                                                style="background: #4d1b7e;"><span>OK</span></b-button>
                                        </div>
                                    </div>
                                </template>
                            </b-modal>
                        </div>
            </section>
            <!-- Products components Ended-->
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Products from './products.vue'
import Collections from './collections.vue'
import Tags from './tags.vue'
import Vendors from './vendors.vue'
import { mapGetters } from "vuex";
export default {
    props: {
        id: String
    },
    components: {
        Products,
        Collections,
        Tags,
        Vendors
    },
    watch: {
        dontRemindAgain(newValue) {
            if (newValue)
                localStorage.setItem('dont_remind_me_change_match', true)
            else
                localStorage.removeItem('dont_remind_me_change_match')

        },

    },

    computed: {
        ...mapGetters(["getEditorSettings", "GetTokenFromMetaTag", "getQuizID", "getNavbarData"]),
        questionList() {

            return this.QuestionsList
        },
        isShopifyUser() {
            return this.getNavbarData.isShopifyUser?this.getNavbarData.isShopifyUser:false;
        },
        isSaveBtnVisible() {
            let check = false;
            if (this.activeCategory == 'products') {
                if ((this.selected.questionIndex >= 0) && this.selected.optionIndex >= 0 && this.products.all.length > 0)
                    check = true
            }
            return check
        },

        activeCategory: {

            get: function () {
                let map_category = 'products'
                if (this.QuestionsList.length > 0) {// eslint-disable vue/no-side-effects-in-computed-properties
                    map_category = this.QuestionsList[this.selected.questionIndex].options[this.selected.optionIndex].map_category
                    let options = this.QuestionsList[this.selected.questionIndex].options[this.selected.optionIndex];
                    for (let index in options)
                        if (index.includes("selected") && (!index.includes(map_category))) {       // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                            this.QuestionsList[this.selected.questionIndex].options[this.selected.optionIndex][index] = []

                        }
                    map_category != '' ? map_category : 'products'
                }
                return map_category
            },
            set: function (newValue) {
                this.QuestionsList[this.selected.questionIndex].options[this.selected.optionIndex].map_category = newValue

            }
        }

    },
    data() {
        return {
            isLoading: false,
            dontRemindAgain: false,
            saveLoading: false,
            quizID: null,
            QuestionsList: [],
            clickedCategory: null,
            isLoadingBtn: false,
            actionBtns: ['products', 'collections', 'tags', 'vendors'],
            isLoadingFirstTime: true,
            selected: {
                questionIndex: 0,
                optionIndex: 0,
                questionOptions: null,
            },
            TagsList: {
                all: [],
                selected: []
            },
            CollectionList: {
                all: [],
                selected: []
            },
            VendorList: [],
            products: {
                selected: [],
                all: []
            },

        }
    },
    mounted() {

        setTimeout(() => {
            this.getProductData()
        }, 2000)

    },
    methods: {
        mapCategoryCount(optionIndex) {
            let count = 0;
            let category = this.QuestionsList[this.selected.questionIndex].options[optionIndex].map_category;
            for (let index in this.QuestionsList[this.selected.questionIndex].options[optionIndex]) {
                if (index.includes(category)) {
                    count = this.QuestionsList[this.selected.questionIndex].options[optionIndex][index].length;
                    break;
                }
            }
            return count
        },
        ModalActions(ok) {
            if (ok)
                this.activeCategory = this.clickedCategory
            this.$bvModal.hide('confirmModal')
        },
        OpenConfirmModal(button) {
            if (!localStorage.getItem('dont_remind_me_change_match')) {
                this.clickedCategory = button;
                if (button !== this.activeCategory)
                    this.$bvModal.show('confirmModal')
                else {
                    this.activeCategory = button
                }
            }
            else
                this.activeCategory = button
        },
        selectedProductByCategory() {
            const selectedQuestion = this.QuestionsList[this.selected.questionIndex];
            selectedQuestion.options.forEach((option, index) => {
                let selectedCollections = [];
                let SelectedTags = [];
                let SelectedVendors = [];
                let selectedCollectionProduct = [];
                let selectedTagProducts = [];
                let selectedVendorProducts = [];
                if (option.map_category == 'collections') {
                    option.selected_collections.forEach((selectedCollection) => {
                        this.CollectionList.all.forEach(collection => {
                            if (selectedCollection.collection_id == collection.external_id && !selectedCollections.includes(collection))
                                selectedCollections.push(collection)
                        })
                    })
                    this.products.all.forEach(product => {
                        if (product.collections != null) {

                            selectedCollections.forEach(collection => {
                                product.collections.forEach(product_collection => {
                                    if (collection.title == product_collection && !selectedCollectionProduct.includes(product))
                                        selectedCollectionProduct.push({
                                            product_id: product.id,
                                            question_option_id: option.id
                                        })
                                }
                                )
                            })

                        }
                    })
                    selectedQuestion.options[index].selected_products = selectedCollectionProduct
                }
                else if (option.map_category == 'tags') {
                    option.selected_tags.forEach((selectedTags) => {
                        this.TagsList.all.forEach(tag => {
                            if (selectedTags.product_tag_id == tag.id && !SelectedTags.includes(tag))
                                selectedCollections.push(tag)
                        })
                    })
                    this.products.all.forEach(product => {
                        if (product.collections != null) {

                            SelectedTags.forEach(Tags => {
                                product.collections.forEach(product_collection => {
                                    if (Tags.title == product_collection && !selectedTagProducts.includes(product))
                                        selectedTagProducts.push({
                                            product_id: product.id,
                                            question_option_id: option.id
                                        })
                                }
                                )
                            })

                        }
                    })
                    selectedQuestion.options[index].selected_products = selectedTagProducts

                }

                else if (option.map_category == 'vendors') {
                    option.selected_vendors.forEach((selectedVendors) => {
                        this.VendorList.forEach(vendor => {
                            if (selectedVendors.product_vendor_id == vendor.id && !SelectedTags.includes(vendor))
                                SelectedVendors.push(vendor)
                        })
                    })
                    this.products.all.forEach(product => {
                        if (product.vendors != null) {
                            SelectedVendors.forEach(vendor => {
                                if (vendor.title && !SelectedVendors.includes(vendor))
                                    selectedVendorProducts.push(vendor)
                            })

                        }
                    })
                    selectedQuestion.options[index].selected_products = selectedVendorProducts
                }

            })
        },
        async saveData() {
            this.saveLoading = true;
            if(this.isShopifyUser) this.selectedProductByCategory();
            const selectedQuestion = this.QuestionsList[this.selected.questionIndex];
            try {

                let data = {
                    mapQuestions: JSON.stringify(selectedQuestion),
                    _token: this.GetTokenFromMetaTag,
                    quizId: this.getQuizID,
                };
                const response = await axios.post(`/saveProductMap`, data);
                if (response.status == 200) {
                    this.$toasted.show("Product Match Saved", {
                        theme: "toasted-primary",
                        position: "bottom-center",
                        duration: 2000,
                        action: {
                            class: "customToast",
                        },
                    });

                }
            } catch (error) {
                if (error) {
                    this.$toasted.show("Error occured ", {
                        theme: "toasted-primary",
                        type: "error",
                        position: "bottom-center",
                        duration: 2000,
                    });
                }

                throw error;

            }
            finally {
                this.saveLoading = false
            }

        },
        selectedItemCount(option) {
            let count = 0;
            // const option = this.QuestionsList[this.selected.questionIndex].options[this.selected.optionIndex];
            if (option) {
                if (this.activeCategory == 'products')
                    count = option.selected_products.length
                if (this.activeCategory == 'collections')
                    count = option.selected_collections.length
                if (this.activeCategory == 'tags')
                    count = option.selected_product_tags.length
            }
            return count;
        },

        async getProductData() {
            this.isLoading = true

            let data = {
                _token: this.GetTokenFromMetaTag,
                quizId: this.getQuizID
            };
            await axios.post(`/loadProductMap`, data).then(response => {
                if (response.status == 200) {
                    this.QuestionsList = response.data.data.questions;
                    this.products.all = response.data.data.products;
                    this.TagsList.all = response.data.data.tagsList;
                    this.CollectionList.all = response.data.data.collectionList;
                    this.selected.questionOptions = this.QuestionsList[this.selected.questionIndex].options
                    this.VendorList = response.data.data.vendorList;
                }
                else
                    this.$toasted.show("Error occurred ", {
                        theme: "toasted-primary",
                        position: "bottom-center",
                        duration: 2000,
                    });
            }
            ).catch(error => {
                this.$toasted.show("Error occurred ", {
                    theme: "toasted-primary",
                    position: "bottom-center",
                    duration: 2000,
                });
                console.log(error)
            })


            this.isLoadingFirstTime = false;
            this.isLoading = false

        },

        selectQuestion(question, index) {
            this.selected.optionIndex = 0;
            this.selected.questionIndex = index
            this.selected.questionOptions = question.options
        },
        selectOption(index) {
            this.selected.optionIndex = index;

        },
        async importItems(e) {

            let copyAllItems = {
                saveCollections: 0,
                saveProducts: 0,
                saveProductTags: 0,
                saveProductVendors: 0,
                saveProductWithVariants: 0,
                allVariantStatus: 0,
                isJobQueue: false,
                quizId: parseInt(localStorage.getItem('QuizID'))
            }
            if (e.title == 'saveProducts')
                copyAllItems['allVariantStatus'] = 1
            copyAllItems[e.title] = 1
            await axios.post("/copyAllShopifyProducts", copyAllItems,
            )
                .then((resp) => {

                    if (resp.status != 200) {
                        this.$toasted.show("Error occurred ", {
                            theme: "toasted-primary",
                            type: "error",
                            position: "bottom-center",
                            duration: 2000,
                        });
                    }
                    if (e.title == 'saveProductTags') {
                        this.TagsList.all = resp.data.data.productTags
                    }
                    else if (e.title == 'saveCollections') {
                        this.CollectionList.all = resp.data.data.collections
                    }
                    else if (e.title == 'saveProductVendors') {
                        this.VendorList = resp.data.data.productVendors
                    }
                    else if (e.title == 'saveProducts') {
                        this.products.all = resp.data.data.products
                    }
                    this.$toasted.show("Imported Successfully", {
                        theme: "toasted-primary",
                        position: "bottom-center",
                        duration: 2000,
                    });
                }).catch((error) => {
                    this.$toasted.show("Error occurred ", {
                        theme: "toasted-primary",
                        type: "error",
                        position: "bottom-center",
                        duration: 2000,
                    });
                    console.log(error)
                });
            e.callBackFunction()
        },
    }
}
</script>
<style>
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #4d1b7e;
    background-color: #4d1b7e;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #4d1b7e;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgb(77 27 126 / 12%);
}

.category-btn:hover {
    color: rgb(77, 27, 126) !important;
}

.pointer-event-none {
    pointer-events: none;
}

.container {
    transition: 0.5s ease all;
}

/* .selectedProduct {
    background: #4d1b7e14;
    border-radius: 6px;
} */

.active-question {
    color: rgb(24, 25, 28) !important;
    font-weight: 500 !important;
    background: rgba(179, 175, 182, 0.1) !important;
}

.active-question .Order {
    flex: 0.08 1 0%;
    text-align: center;
    background: rgb(77, 27, 126);
    opacity: 0.8;
    border-radius: 2px;
}

.Order {
    background: #b3afb6;
    width: 25px !important;
    height: 20px;
    border-radius: 2px;
    color: #ffffff;
}

.Option-order {
    background: #b3afb6;
    /* width: 25px !important; */
    min-width: 25px !important;
    padding: 0 5px;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    height: 20px;
    color: #ffffff;
    border-radius: 2px;
}

.options {
    cursor: pointer;
}

.options:not(.active-option):hover {
    background: #b3afb626;
}

.active-option {
    color: rgb(24, 25, 28);
    font-weight: 500;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 15%) 0px 6px 12px -6px;
}

.option-order {
    background: #b3afb6;
    /* width: 25px !important; */
    min-width: 25px !important;
    padding: 0 5px;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    height: 20px;
    color: #ffffff;
    border-radius: 2px;
    text-align: center !important;
}

.active-option .Option-order {
    background: linear-gradient(rgb(255, 184, 0) 0%, rgb(255, 162, 0) 56.77%, rgb(255, 162, 0) 90.1%) !important;
    opacity: 0.8 !important;
    border-radius: 2px !important;
}

.btn-row {
    border-bottom: 1px solid #B3AFB6;
}

.active-btn {
    font-weight: 600;
    color: black !important;
    border-bottom: 2px solid rgb(77, 27, 126) !important;
    border-radius: 0px !important;
    ;

}

.selected-item {
    display: none;
}

.active-option:hover .order-info .selected-item {
    display: block;
    font-size: 12px;
    color: rgb(255 184 0);
}

.options:not(.active-option):hover .order-info .selected-item {
    display: block;
    font-size: 12px;
    color: #b3afb6;
}

.refresh {

    animation: refresh-animation 2s linear infinite;
}

@keyframes refresh-animation {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}</style>